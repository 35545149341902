<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <b-card>
      <validation-observer ref="updateForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="updateForm"
          @submit="submit"
          :form="$refs.updateForm"
          :disabled="invalid"
        >
          <n-input
            :fields="fields"
            v-model="data"
            :initValue="initData"
            class="data-form"
          >
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('update', 'bet-setting')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NSingleSelect from "@/components/NSingleSelect";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import BetSettingMenu from "./BetSettingMenu.vue";

const BetSettingRepository = Repository.get("betSetting");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    NAsyncSingleSelect,
    BetSettingMenu,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        tax: 0,
        maxTotalBet: 0,
      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      BetSettingRepository.show().then((response) => {
        this.initData = {
          ...response.data.data,
        };
      });
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          BetSettingRepository.update(this.data)
            .then((response) => {})
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updateForm.setErrors(error.response?.data?.message);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      return value;
    });

    return { fields };
  },
};
</script>